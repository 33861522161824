@use '/src/app/styles/variables' as *;

.navLink {
  color: $white;
  text-decoration: none;
  text-align: center;
  width: (100% / 2);

  :hover {
    color: $turquoise;
  }

  &__active {
    color: $turquoise;
  }
}
