@use '/src/app/styles/variables' as *;

.main {
  padding: 30px 0;
  height: $mainContentHeight;
  overflow-y: scroll;

  .form {
    padding: 0 0 0 15px;
    margin: 0 auto;
  }

  .title {
    font-weight: 400;
    font-size: 24px;
    font-weight: 400;
    width: 40%;
    margin: 0;
  }

  .datePicker {
    width: 100%;
  }

  .formCaption {
    font-size: 20px;
    font-weight: 400;
    margin-top: 30px;
  }

  .formItemCaption {
    height: fit-content;
    width: 40%;
    font-size: 16px;
  }

  .formItemInput {
    margin: 0;
    width: 60%;
  }

  .formItemInputSmall {
    margin: 0;
    width: 30%;
    min-width: 150px;
  }

  .formItemNumber {
    margin: 0;
    width: 15%;
  }

  .pointWrapper {
    margin-bottom: 30px;
  }

  .pointTitle {
    margin-bottom: 10px;
  }

  .addPointButtonWrapper {
    text-align: center;

    .addPointButton {
      &:hover {
        color: $turquoise;
        border-color: $turquoise;
      }
    }
  }

  .formRow {
    margin-bottom: 7px;
  }

  .headerRow {
    margin-bottom: 17px;
  }

  .removePointButtonWrapper {
    width: 10%;
    text-align: right;
  }

  .pointCaption {
    width: 50%;
    font-size: 17px;
  }

  .endPointCaption {
    font-size: 14px;
    color: $gray1;
  }

  .saveButtonWrapper {
    margin-top: 25px;
    text-align: center;

    .saveButton {
      width: 80%;
      height: 50px;
      background-color: $turquoise;
      border-color: $turquoise;

      &:hover {
        background-color: $turquoise !important;
        border-color: $turquoise !important;
        color: black !important;
      }
    }
  }
}
